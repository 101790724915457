import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Section Images
import bgImage1 from "assets/images/sexual-health-test/threesections/test-talk-treat.jpg"
import bgImage2 from "assets/images/sexual-health-test/threesections/HappierPatientsClinicians.jpg"


const StyledThreeSections = styled.section`
	margin: 0 auto;
	padding: 0;
	overflow: hidden;
  
  ${breakpoint.medium`
    padding: 30px 0 0;
  `}

    ${Container} {
    	max-width: 100%;
	}

	.leftBox, .rightBox {
  		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0px;
		right: auto;
		position: absolute;
		width: 100%;
		height: 100%;
		color: ${colors.charcoal};
    	justify-content: center;
    	text-align: center;
    	margin: 0 auto;
    	padding: 40px;
    	
		${breakpoint.small`
  			background-color: rgba(255, 255, 255, 0.5);
			width: 50%;
		`}

		${breakpoint.medium`
    		padding: 20px 3%;
		`}
    	
		${breakpoint.large`
    		padding: 20px 6%;
		`}
	 }
  
	.rightBox {
		right: 0px;
		left: auto;
	  }
    
  .darkPurple, .lightPurple  {
  		display: flex;
		background-color: ${colors.purpleDark};
		color: ${colors.white};
    	text-align: center;
    	max-height: 600px;
 		width: 100%;
		height: 100%;
    	margin: 0 auto;
    	padding: 20px;
    	justify-content: center!important;

		${breakpoint.xs`
			width: 50%;
		`}

		${breakpoint.medium`
    		padding: 20px 3%;
		`}
    	
		${breakpoint.large`
    		padding: 20px 6%;
		`}
	}
	
	.lightPurple {
		background-color: ${colors.purpleLightNew};
	}

	.parallaxBg1 {
		background: url(${bgImage1});
		background-size: cover;
		background-position-x: center;
		background-position-y: center;
		background-repeat: no-repeat;
		background-attachment: scroll;
		width: 100%;
		height: 100%;
		min-height: 400px;
	
		${breakpoint.small`
			min-height: 600px;
		`}
	
		${breakpoint.medium`
			background-attachment: fixed;
		`}
		}
	}
	
	.parallaxBg2 {
		background: url(${bgImage2});
		background-size: cover;
		background-position-x: left;
		background-position-y: bottom;
		background-repeat: no-repeat;
		background-attachment: scroll;
		width: 100%;
		height: 100%;
		min-height: 400px;
	
		${breakpoint.small`
			min-height: 600px;
		`}
	
		${breakpoint.medium`
			background-attachment: fixed;
		`}
		}
`

const StyledContainer = styled(Container)`

	h2 {
		padding: 0;
		margin: 0 0 0.8rem 0;
		
		${breakpoint.medium`
			margin-bottom: 1.5rem;
		`}
	}

	p {
		font-size: 0.9em;
		line-height: 1.4em;
		margin: 0;
		padding: 0;
	}

	.grid-container {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr;
		align-items: center;
		justify-items: center;
	}
	
		.grid-text {
			font-size: 15px;
			
		${breakpoint.small`
			font-size: 18px;
		`}		
		
		}
		
		.illustration {
			width: 100%;
			display: block;
			max-width: 350px;

		${breakpoint.small`
			max-width: 450px;
		`}		

		${breakpoint.medium`
			max-width: 550px;
		`}		

		}

	.sub, .subWhite {
		font-style: italic;
		margin-top: 10px;
		line-height: 1.1em;
		font-size: x-small;
		color: ${colors.graySelect};

		${breakpoint.medium`
		  font-size: small;
		`}

	}
	
	 .subWhite {
		color: ${colors.white};
	}
`

const StyledSection = styled.div`
		position: relative;
		border-bottom-width: 0;
		margin: 0 auto;
  		display: flex;
		flex-direction: column;
		min-height: 400px;

		${breakpoint.small`
			min-height: 600px;
		`}		  
`

const StyledColoredSection = styled.div`
		position: relative;
		border-bottom-width: 0;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-width: 100%;

		${breakpoint.small`
			flex-direction: row;
		`}		
		

		.column {
			flex-direction: row;
			flex-basis: 100%;
			flex-wrap: wrap;
			flex: 1;
		    align-content: center;
			width: 100%;
			height: auto;
			max-width: 100%;
			min-height: 400px;

			${breakpoint.small`
				min-height: 600px;
			`}		
		}
  
`

const ThreeSections = props => {
	return (
		<StyledThreeSections>
			<StyledSection className="parallaxBg1">
					<StyledContainer className="rightBox">
						<h2>Test, talk, treat -<br />in the same visit.</h2>
						<p>
							Fast results, in under 30 minutes, empower you to have a confident conversation about more than just a diagnosis:
							Listening, empathizing and providing a teachable moment that lasts long after.
						</p>
					</StyledContainer>
			</StyledSection>
			<StyledColoredSection>
				<StyledContainer className="column lightPurple">
					<h2>More adherence.<br />Less community spread.</h2>
					<p>A recent study found that doctors who practice a “whole person” approach
						receive 160% higher adherence than those who do not<sup>6</sup>.</p>
					<p className="subWhite">6. J Fam Pract 1998; 47:213-220</p>
				</StyledContainer>
				<StyledContainer className="column darkPurple">
					<div className="grid-container" data-aos="slide-left" data-aos-once="false" data-aos-delay="200" data-aos-duration="1200" data-aos-easing="ease-out-cubic">
						<StaticImage
							src="../../../assets/images/sexual-health-test/threesections/CommunityVisby.png"
							width={550}
							alt="Illustration of community"
							className="illustration"
						/>
					</div>
				</StyledContainer>
			</StyledColoredSection>
			<StyledSection className="parallaxBg2">
					<StyledContainer className="rightBox">
						<h2>Happier patients.<br />Happier clinicians.</h2>
						<p>
							Patients who feel cared for have better outcomes<sup>7</sup>; we believe better outcomes lead to happier clinicians.
							Happy clinicians may make for even happier, healthier patients — a desirable loop of healthy reinforcement.
						</p>
						<p className="sub">7. Zolnierek KB, Dimatteo MR. Physician communication and patient adherence to treatment: a meta-analysis. Med Care. 2009 Aug;47(8):826-34. doi: 10.1097/MLR.0b013e31819a5acc. PMID: 19584762; PMCID: PMC2728700.</p>
					</StyledContainer>
			</StyledSection>
		</StyledThreeSections>
	)
}

export default ThreeSections
